import styles from "./CircumstanceCard.module.css";
import PropTypes from "prop-types";
import Card from "shared/Card";

/**
 * Displays info about the security circumstances
 */

export function CircumstanceCard({ circumstances }) {
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.cardHeader}>
            SECURITY SPECIAL CIRCUMSTANCES
          </div>
        </div>
        <div className={styles.hr}></div>

        <div>
          {circumstances.securityNotes.saved ||
            circumstances.securityNotes.value ||
            "N/A"}
        </div>
      </div>
    </>
  );
  return <Card children={cardContents} />;
}

CircumstanceCard.propTypes = {
  circumstances: PropTypes.object,
};
