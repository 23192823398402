import { useQuery, useMutation, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useCallback } from "react";

export function useArchivedEventsPage() {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(["archivedEvents"], () =>
    makeAuthedRequest(`v1/events/view/archived`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const cloneEvent = useMutation(
    async (event) => {
      return makeAuthedRequest(`v1/events/event/clone`, {
        method: "POST",
        body: JSON.stringify(event),
      }).then(async (req) => {
        if (req.ok) {
          return req.json();
        } else {
          console.log("ERROR ", req);
          const json = await req.json();
          throw new Error(json.message);
        }
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["events"]);
      },
      onError: (error) => {
        console.error("Error cloning event:", error);
      },
    },
  );

  return {
    data,
    cloneEvent,
    isLoading,
    refetch,
  };
}

export function useSettings() {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(["settings"], () =>
    makeAuthedRequest(`v1/events/setting/user`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const settingsPut = async (newSettings) => {
    try {
      const response = await makeAuthedRequest(`v1/events/setting/user`, {
        method: "PUT",
        body: JSON.stringify(newSettings),
      });

      // if (response.ok) {
      //   const updatedSettings = await response.json();
      //   // Invalidate the query to ensure fresh data is fetched next time
      //   queryClient.invalidateQueries(["settings"]);
      //   return updatedSettings;

      if (response.ok) {
        return await response.json();
      } else {
        const errorBody = await response.json();
        throw new Error(errorBody.message);
      }
    } catch (error) {
      console.error("ERROR in settingsPut: ", error);
      throw error;
    }
  };

  return {
    data,
    status,
    isLoading,
    refetch,
    settingsPut,
  };
}
