import PropTypes from "prop-types";
import styles from "./EventHostsCard.module.css";
import { HostCardInteractive } from "Events/Components/HostCardInteractive/HostCardInteractive";
import Card from "shared/Card";

/**
 *  Displays info about the different hosts
 */
export function EventHostsCard({ hosts }) {
  const hostList = hosts?.saved?.length ? hosts.saved : hosts?.value || [];

  if (!Array.isArray(hostList)) {
    console.error("EventHostsCard - Expected an array, but got:", hostList);
    return <p>Error loading hosts</p>;
  }

  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.cardHeader}>EVENT HOST(S)</div>
        <div className={styles.hr} />

        {hostList.length > 0 ? (
          hostList.map((item, index) => (
            <div className={styles.hostCard} key={index}>
              <HostCardInteractive
                host={{
                  number: item.iboNumber,
                  name: item.name,
                  userName: item.username, // Corrected casing
                  phoneNumber: item.phoneNumber || "N/A",
                  email: item.email || "N/A",
                  accessLevel: item.isPrimary ? "Primary" : "Secondary",
                }}
              />
              <div className={styles.hr} />
            </div>
          ))
        ) : (
          <p>No hosts available</p>
        )}
      </div>
    </>
  );

  return <Card children={cardContents} />;
}

EventHostsCard.propTypes = {
  hosts: PropTypes.shape({
    saved: PropTypes.array,
    value: PropTypes.array,
  }),
};
