import PropTypes from "prop-types";
import styles from "./HostCard.module.css";

/**
 *
 * Displays information about a host
 *
 */

export function HostCard({ host }) {
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.left}>
          <div className={styles.item}>IBO Number</div>
          <div className={styles.item}>Name</div>
          <div className={styles.item}>Username</div>
          {/* <div className={styles.item}>Phone</div> */}
          <div className={styles.item}>Email</div>
          <div className={styles.item}>Location</div>
        </div>

        <div>
          <div className={styles.item}>{host.iboNumber}</div>
          <div className={styles.item}>{host.name}</div>
          <div className={styles.item}>{host.username}</div>
          {/* <div className={styles.item}>{host.phoneNumber}</div> */}
          <div className={styles.item}>{host.email}</div>
          <div className={styles.item}>{host.location}</div>
        </div>

        {/*}
        <div className={styles.imageIcon}>
          <div className={styles.imageIconCircle}></div>
          <div className={styles.imageIconSquare}></div>
        </div>
        */}
      </div>
    </div>
  );
}

HostCard.propTypes = { host: PropTypes.object };
