import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";
import toast from "react-hot-toast";
import createNotification from "shared/@utils/createNotification";
import { react, useState } from "react";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";

export function useEditEventsPage({ eventId }) {
  const [errorModal, setErrorModal] = useState({
    isOpen: false,
    message: "",
  });
  const [isPublishing, setIsPublishing] = useState(false);
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    ["event", eventId],
    () =>
      makeAuthedRequest(`v1/events/id/${eventId}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    { staleTime: 0 }
  );

  const eventEditPut = useMutation(
    (eventObject) =>
      makeAuthedRequest(`v1/events/event/update/${eventId}`, {
        method: "PUT",
        body: JSON.stringify(eventObject),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["event", eventId]);
        queryClient.refetchQueries(["events"]);
        createNotification({
          type: "success",
          title: "Saved",
          message: "Successfully Updated",
          autoDismiss: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("Something went wrong!");
      },
    }
  );

  const eventEditPutSilent = useMutation(
    (eventObject) =>
      makeAuthedRequest(`v1/events/event/update/${eventId}`, {
        method: "PUT",
        body: JSON.stringify(eventObject),
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["events"]);
        createNotification({
          type: "success",
          title: "Saved",
          message: "Successfully Updated",
          autoDismiss: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast.error("Something went wrong!");
      },
    }
  );

  const eventPublish = useMutation(
    ["publishEventsPut"],
    () =>
      makeAuthedRequest(`v1/events/event/publish`, {
        method: "PUT",
        body: JSON.stringify({ eventId: eventId }),
      }).then(async (res) => {
        if (!res.ok) {
          const body = await res.json();
          // Attach the message to the error object
          throw { message: body.error?.message };
        }
      }),
    {
      onMutate: () => {
        // make sure the EventPublishModal does not show when the publish process starts
        setIsPublishing(false);
      },
      onSuccess: () => {
        //open the modal only when true
        setIsPublishing(true);
      },
      onError: (err) => {
        setIsPublishing(false);
        setErrorModal({
          isOpen: true,
          message:
            err.message ||
            "Event failed to Publish - An unexpected error occurred.",
        });
      },
    }
  );

  const closeErrorModal = () => setErrorModal({ isOpen: false, message: "" });

  return {
    data,
    isLoading,
    refetch,
    eventEditPut,
    eventEditPutSilent,
    eventPublish,
    errorModal,
    closeErrorModal,
    isPublishing,
  };
}
