import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./VenueList.module.css";
import classNames from "classnames";
import VenueRow from "Events/Components/VenueRow";
import TableSortItem from "shared/TableSortItem";
import EditVenueModal from "Events/Components/EditVenueModal";
import DeleteModal from "Events/Components/DeleteModal";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import Loader from "shared/Loader";
import ComparisonModal from "Events/Components/ComparisonModal";
import { useManageVenuesPage } from "Events/Pages/ManageVenuesPage/useManageVenuesPage";

/**
 * Displays the information of all past venues with a header and pagination on bottom.
 * Columns can be sorted in alphabetical and reverse alphabetical order by clicking the array next to the column label
 */

export function VenueList({
  venueList,
  onEdit,
  sort,
  setSort,
  isVenuesLoading,
  refetchVenues,
  refetchV2Venues,
}) {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();
  const { resolveAddress, updateVenue, archiveVenue } = useManageVenuesPage({
    ruleset: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    page: 1,
    limit: 15,
  });
  const [stateIdToNameMap, setStateIdToNameMap] = useState({});

  // Convert state data to a map for easy lookup
  useEffect(() => {
    if (states && !isLoadingStates) {
      const map = states.reduce((acc, state) => {
        acc[state.stateId] = state.state;
        return acc;
      }, {});
      setStateIdToNameMap(map);
    }
  }, [states, isLoadingStates]);

  const [isLoading, setIsLoading] = useState(isVenuesLoading);
  useEffect(() => {
    setIsLoading(isVenuesLoading);
  }, [isVenuesLoading]);

  const [showEditVenue, setShowEditVenue] = useState(false);
  const [showDeleteVenue, setShowDeleteVenue] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [isCompLoading, setCompLoading] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({
    street1: "",
    street1: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (suggestions.length > 0) {
      setComparisonModalOpen(false);
      setShowEditVenue(true);
    }
  }, [suggestions]);
  const [formData, setFormData] = useState({
    venueId: 0,
    ruleset: "",
    venueName: "",
    street1: "",
    street2: "",
    city: "",
    state: "", //StateName - is transformed to StateId when being passed
    country: "", //CountryName - ||
    zip: "",
    latitude: null,
    longitude: null,
    phone: "",
    url: "",
    fax: "",
    rooms: { name: "", capacity: "" },
    isVerified: false,

    email: "", // Not used in V1 Events
  });

  const getStateNameById = (stateId) => {
    return stateIdToNameMap[stateId] || "Unknown State";
  };

  const editVenue = async (updatedVenue) => {
    if (updatedVenue.ruleset !== "v2.0") {
      setCompLoading(true);
      setFormData(updatedVenue);
      setShowEditVenue(false);
      setComparisonModalOpen(true);

      const requiredFields = [
        { field: updatedVenue.venueName, errorMessage: "Venue Name Missing" },
        { field: updatedVenue.street1, errorMessage: "Address 1 Missing" },
        { field: updatedVenue.city, errorMessage: "City Missing" },
        {
          field: updatedVenue.zip,
          errorMessage: "Zip/Postal Code Missing",
        },
      ];

      let newSuggestions = [];

      requiredFields.forEach(({ field, errorMessage }) => {
        if (!field) {
          newSuggestions.push(errorMessage);
        }
      });

      if (newSuggestions.length > 0) {
        setSuggestions(newSuggestions);
        setComparisonModalOpen(false);
        setShowEditVenue(true);
        return;
      }

      const resolveAddressData = {
        line1: updatedVenue.street1,
        line2: updatedVenue.street2,
        city: updatedVenue.city,
        state: updatedVenue.state,
        country: updatedVenue.country,
        postalCode: updatedVenue.zip,
      };

      try {
        const returnAddress = await resolveAddress.mutateAsync(
          resolveAddressData
        );

        if (returnAddress?.suggestions?.length > 0) {
          setSuggestions(returnAddress.suggestions);
        } else {
          setSuggestedAddress(returnAddress);
          setSuggestions([]);
        }
        setCompLoading(false);
      } catch (error) {
        console.error("Error Resolving Address:", error);
        setCompLoading(false);
      }
    } else {
      setShowEditVenue(false);
      submitVenue(updatedVenue);
    }
  };

  const handleUseSuggested = () => {
    const newFormData = {
      ...formData,
      street1: suggestedAddress.line1,
      street2: suggestedAddress.line2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      zip: suggestedAddress.postalCode,
      country: suggestedAddress.country,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: true,
    };
    setFormData(newFormData);
    submitVenue(newFormData);
  };

  const handleUseOriginal = () => {
    const newFormData = {
      ...formData,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: false,
    };
    setFormData(newFormData);
    submitVenue(newFormData);
  };

  const submitVenue = async (formDataToSubmit) => {
    setCompLoading(true);
    setIsLoading(true);

    const { rooms, ...otherFormData } = formDataToSubmit;
    let filteredRooms = rooms;
    // Filter out rooms with empty room and capacity
    if (rooms) {
      filteredRooms = rooms
        .filter(
          (room) => room.name !== "" && room.capacity !== "" // Exclude rooms with empty room and capacity
        )
        .map((room) => {
          const { roomId, name: roomName, capacity, ...otherRoomData } = room;
          return {
            name: roomName,
            capacity,
            // Include roomId only if it exists
            ...(roomId ? { roomId } : {}),
            ...otherRoomData,
          };
        });

      // If there are no valid rooms after filtering, set filteredRooms to undefined
      if (filteredRooms.length === 0) {
        filteredRooms = undefined;
      }
    }

    let selectedStateObject;
    if (formDataToSubmit.state.length == 2) {
      selectedStateObject = states.find(
        (state) =>
          state.abbreviation.toLowerCase() ==
          formDataToSubmit.state.toLowerCase()
      );
    } else {
      selectedStateObject = states.find(
        (state) =>
          state.state.toLowerCase() == formDataToSubmit.state.toLowerCase()
      );
    }

    let updatedVenue;
    if (formDataToSubmit.ruleset == "v2.0") {
      updatedVenue = {
        venueId: formDataToSubmit.venueId,
        ruleset: formDataToSubmit.ruleset,
        netSuiteId: formDataToSubmit.netSuiteId,
        latitude: formDataToSubmit.latitude,
        longitude: formDataToSubmit.longitude,
        phone: formDataToSubmit.phone,
        url: formDataToSubmit.url,
        // fax: formDataToSubmit.fax,
        email: formDataToSubmit.email,
        // Conditionally include the rooms array only if there are valid rooms
        ...(filteredRooms ? { rooms: filteredRooms } : {}),
        // Conditionally include the email field based on ruleset
        // ...(!formDataToSubmit.ruleset.toLowerCase().includes("v1.0")
        //   ? { email: formDataToSubmit.email }
        //   : {}),
      };
    } else {
      const stateId = selectedStateObject.stateId;
      const countryId = selectedStateObject.countryId;

      updatedVenue = {
        venueId: formDataToSubmit.venueId,
        ruleset: formDataToSubmit.ruleset,
        venueName: formDataToSubmit.venueName,
        street1: formDataToSubmit.street1,
        street2: formDataToSubmit.street2 || "",
        city: formDataToSubmit.city,
        stateId: stateId,
        countryId: countryId,
        zip: formDataToSubmit.zip,
        latitude: formDataToSubmit.latitude,
        longitude: formDataToSubmit.longitude,
        phone: formDataToSubmit.phone,
        url: formDataToSubmit.url,
        fax: formDataToSubmit.fax,
        isVerified: formDataToSubmit.isVerified,
        // Conditionally include the rooms array only if there are valid rooms
        ...(filteredRooms ? { rooms: filteredRooms } : {}),
        // Conditionally include the email field based on ruleset
        ...(!formDataToSubmit.ruleset.toLowerCase().includes("v1.0")
          ? { email: formDataToSubmit.email }
          : {}),
      };
    }

    try {
      await updateVenue.mutateAsync(updatedVenue).then(() => {
        updatedVenue.ruleset == "v2.0" ? refetchV2Venues() : refetchVenues().then(() => {
          setComparisonModalOpen(false);
          setCompLoading(false);
          setIsLoading(false);
          setSelectedVenue(null);
        });
      });
    } catch (error) {
      console.error("Error Updating Venue:", error);
      setComparisonModalOpen(false);
      setCompLoading(false);
      setIsLoading(false);
    }
  };

  const handleEditVenue = (venue) => {
    setSelectedVenue(venue);
    setShowEditVenue(true);
  };

  const handleDeleteVenue = async () => {
    //Is actually Archive not delete
    setCompLoading(true);
    setIsLoading(true);

    const archiveData = {
      venueId: selectedVenue.venueId,
      ruleset: selectedVenue.ruleset,
    };

    try {
      await archiveVenue.mutateAsync(archiveData).then(() => {
        refetchVenues().then(() => {
          setShowDeleteVenue(false);
          setIsLoading(false);
        });
      });
    } catch (error) {
      console.error("Error Archiving Event:", error);

      setShowDeleteVenue(false);
      setIsLoading(false);
    }
  };

  const onDeleteClicked = () => {
    setShowDeleteVenue(true);
    setShowEditVenue(false);
  };

  const handleSortChange = (property) => {
    // Toggle between "asc" and "desc" only, resetting others
    const newSortOrder = sort === `${property}-asc` ? "desc" : "asc";

    // Update sortOrder state to reflect the change
    setSort(`${property}-${newSortOrder}`);
  };

  const [sortedVenueList, setSortedVenueList] = useState(venueList);

  useEffect(() => {
    if (!sort) {
      setSortedVenueList(venueList);
      return;
    }

    const [property, order] = sort.split("-");
    const isAsc = order === "asc";

    const sortedList = [...venueList].sort((a, b) => {
      let valA = (a[property] || "").toString();
      let valB = (b[property] || "").toString();

      if (property === "state") {
        valA = stateIdToNameMap[a.state] || "Unknown State";
        valB = stateIdToNameMap[b.state] || "Unknown State";
      }

      return isAsc ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });

    setSortedVenueList(sortedList);
  }, [venueList]);

  return (
    <div className={styles.container}>
      {/* {isLoading && <Loader />} */}

      <div className={styles.sortGrid}>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("ruleset") ? sort.split("-")[1] : ""}
            title="Ruleset"
            onChange={() => handleSortChange("ruleset")}
            isBlank={!sort || !sort.startsWith("ruleset")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={
              sort && sort.startsWith("venueName") ? sort.split("-")[1] : ""
            }
            title="Venue Name"
            onChange={() => handleSortChange("venueName")}
            isBlank={!sort || !sort.startsWith("venueName")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("city") ? sort.split("-")[1] : ""}
            title="City"
            onChange={() => handleSortChange("city")}
            isBlank={!sort || !sort.startsWith("city")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("state") ? sort.split("-")[1] : ""}
            title="State"
            onChange={() => handleSortChange("state")}
            isBlank={!sort || !sort.startsWith("state")}
          />
        </div>
      </div>

      <div className={styles.venueList}>
        {sortedVenueList && sortedVenueList.length > 0 ? (
          <>
            {sortedVenueList.map((row, index) => (
              <div
                className={classNames(
                  index % 2 === 1
                    ? styles.slightlyDarkerBackground
                    : styles.whiteBG
                )}
                key={index}
              >
                <VenueRow
                  Venue={{
                    ...row,
                    state: row.ruleset
                      ? row.state
                      : getStateNameById(row.state),
                  }}
                  onClick={() => handleEditVenue(row)}
                />
              </div>
            ))}
          </>
        ) : (
          <p className={styles.noVenueText}>No Venue Found</p>
        )}
      </div>

      <EditVenueModal
        isOpen={showEditVenue}
        onCollapse={() => setShowEditVenue(false)}
        onClick={(updatedVenue) => {
          editVenue(updatedVenue);
          // onEdit();
        }}
        onDelete={() => onDeleteClicked()}
        venue={selectedVenue}
        errorsList={suggestions}
      />

      <DeleteModal
        header="ARCHIVE"
        btnName="ARCHIVE"
        description={`Confirm that you would like to archive ${selectedVenue?.venueName}.`} // This action cannot be undone."
        isOpen={showDeleteVenue}
        onCollapse={() => setShowDeleteVenue(false)}
        onClick={handleDeleteVenue}
      />

      <ComparisonModal
        suggestedAddress={suggestedAddress}
        address={formData}
        handleUseSuggested={() => handleUseSuggested()}
        handleUseOriginal={() => handleUseOriginal()}
        handleEditClick={() => {
          setComparisonModalOpen(false);
          setShowEditVenue(true);
        }}
        onClose={() => setComparisonModalOpen(false)}
        isOpen={comparisonModalOpen}
        title={"Confirm Address"}
        loading={isCompLoading}
      />
    </div>
  );
}

VenueList.propTypes = {
  venueList: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
};
