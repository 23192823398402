import { useMutation, useQueryClient, useQuery } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useCallback } from "react";

export function useManageEventsPage() {
  const { makeAuthedRequest } = Auth.useContainer();
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(["events"], () =>
    makeAuthedRequest(`v1/events/view/active`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  const cloneEvent = useMutation(
    async (event) => {
      return makeAuthedRequest(`v1/events/event/clone`, {
        method: "POST",
        body: JSON.stringify(event),
      }).then(async (req) => {
        if (req.ok) {
          return req.json();
        } else {
          console.log("ERROR ", req);
          const json = await req.json();
          throw new Error(json.message);
        }
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["events"]);
      },
      onError: (error) => {
        console.error("Error cloning event:", error);
      },
    }
  );

  const archiveEvent = useMutation(
    async (eventId) => {
      const response = await makeAuthedRequest(`v1/events/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventId }),
      });

      if (response.ok) {
        try {
          return await response.json();
        } catch (error) {
          return response;
        }
      } else {
        const body = await response.json();
        throw new Error(body.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["events"]);
        queryClient.refetchQueries(["archivedEvents"]);
      },
      onError: (error) => {
        console.error("Error archiving event:", error);
      },
    }
  );

  return {
    data,
    cloneEvent,
    archiveEvent,
    isLoading,
    refetch,
  };
}

export function useSettings() {
  const queryClient = useQueryClient();
  const { makeAuthedRequest, isAuthenticated } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(
    ["settings"],
    () =>
      makeAuthedRequest(`v1/events/setting/user`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
      // refetchOnWindowFocus: true,
    }
  );

  const settingsPut = async (newSettings) => {
    try {
      const response = await makeAuthedRequest(`v1/events/setting/user`, {
        method: "PUT",
        body: JSON.stringify(newSettings),
      });

      if (response.ok) {
        const updatedSettings = await response.json();
        queryClient.invalidateQueries(["settings"]);
        return updatedSettings;

        // if (response.ok) {
        //   return await response.json();
      } else {
        const errorBody = await response.json();
        throw new Error(errorBody.message);
      }
    } catch (error) {
      console.error("ERROR in settingsPut: ", error);
      throw error;
    }
  };

  return {
    data,
    status,
    isLoading,
    refetch,
    settingsPut,
  };
}
