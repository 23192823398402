import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./ViewLocalV2EventManagePage.module.css";
import Loader from "shared/Loader";

import { useViewEvents } from "./useViewEvents";

import Header from "shared/Header";
import EventTypeV2Card from "Events/V2Components/EventTypeV2Card";
import EventV2InfoForm from "Events/V2Components/EventV2InfoForm";
import PublishingV2InfoForm from "Events/V2Components/PublishingV2InfoForm";
// import DateV2Form from "Events/V2Components/DateV2Form";
// import VenueCard from "Events/Components/VenueCard";

import VenueV2Form from "Events/V2Components/VenueV2Form";

import TicketingV2Form from "Events/V2Components/TicketingV2Form";
import NotesV2Form from "Events/V2Components/NotesV2Form";
import Button from "shared/Button";
import EventHostsCard from "Events/Components/EventHostsCard";
import VirtualCard from "Events/Components/VirtualCard";
import SessionsV2Form from "Events/V2Components/SessionsV2Form";

export function ViewLocalV2EventManagePage() {
  let history = useHistory();
  const { eventId } = useParams();

  const { data: viewEventData, isLoading, refetch } = useViewEvents(eventId);

  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      dates: {},
      country: "",
      city: "",
      state: "",
      notes: {},
    },
    marketing: {
      alertNotes: {},
      title: "",
      description: "",
      infoNotes: {},
      tentative: "",
      primaryCaption: "",
      secondaryCaption: "",
      visibleCalendar: "",
      visibleTo: "",
      notes: {},
      primaryCaption: {},
      secondaryCaption: {},
    },
    virtual: {
      eventId: {},
      eventLink: {},
      eventType: {},
      eventName: {},
    },
    analytics: {
      sales: {
        soldOut: "",
        capacity: {
          purchase: 0,
          platinum: 0,
        },
      },
      countingTickets: "",
    },
    venue: {
      company: {},
      room: {},
    },
    sessions: [
      {
        type: "",
        sessionName: "",
        description: "",
        speakers: [],
        capacity: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        checkInStartDate: "",
        checkInStartTime: "",
        checkInEndDate: "",
        checkInEndTime: "",
        doorsOpenStartDate: "",
        doorsOpenStartTime: "",
        doorsOpenEndDate: "",
        doorsOpenEndTime: "",
      },
    ],
    hosts: [],
    tickets: {
      saved: [],
      value: [],
    },
    speakers: [],
    visibleTo: [],
  });

  useEffect(() => {
    refetch();
  }, [eventId, refetch]);

  useEffect(() => {
    if (viewEventData && !isLoading) {
      setEventInfo({
        event: {
          name: viewEventData.event?.name,
          ruleset: viewEventData.event?.ruleset,
          location: viewEventData.event?.location,
          jobCode: viewEventData.event?.jobCode,
          city:
            viewEventData?.event?.city?.value ||
            viewEventData?.event?.city?.saved ||
            "",
          state: viewEventData.event?.state || "",
          country: viewEventData.event?.country || "",
          dates: viewEventData.event?.dates || "",
          notes: viewEventData.event?.notes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
        },
        //  marketing
        marketing: {
          alertNotes: viewEventData.marketing?.alertNotes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          displayImage: viewEventData.marketing?.displayImage || "",
          title:
            viewEventData?.marketing?.title?.value ||
            viewEventData?.marketing?.title?.saved ||
            "",
          description:
            viewEventData.marketing?.description?.value ||
            viewEventData.marketing?.description?.saved ||
            "",
          infoNotes: viewEventData.marketing?.infoNotes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          tentative:
            viewEventData.marketing?.tentative?.value ||
            viewEventData.marketing?.tentative?.saved ||
            "",
          primaryCaption: viewEventData.marketing?.primaryCaption || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          secondaryCaption: viewEventData.marketing?.secondaryCaption || {
            saved: "",
            value: "",
            permission: "hidden",
          },
          visibleCalendar:
            viewEventData.marketing?.visibleCalendar?.value ||
            viewEventData.marketing?.visibleCalendar?.saved ||
            "",
          visibleTo: viewEventData.marketing?.visibleTo || "",
          notes: viewEventData.marketing?.notes || {
            saved: "",
            value: "",
            permission: "hidden",
          },
        },
        virtual: {
          eventId: viewEventData.virtual?.eventId || "",
          eventLink: viewEventData.virtual?.eventLink || "",
          eventType: viewEventData.virtual?.eventType || "",
          eventName: viewEventData.virtual?.eventName || "",
        },
        analytics: {
          countingTickets: viewEventData.analytics?.countingTickets || "",
          sales: {
            soldOut: viewEventData.analytics?.sales?.soldOut || "",
            capacity: {
              purchase: viewEventData.analytics?.sales?.capacity?.purchase || 0,
              platinum: viewEventData.analytics?.sales?.capacity?.platinum || 0,
            },
          },
        },
        venue: {
          company: viewEventData?.venue?.company || {},
          room: viewEventData?.venue?.room || {},
        },
        sessions: viewEventData.sessions || [],
        hosts: viewEventData.hosts,
        tickets: { value: viewEventData.tickets || [] },
        speakers: viewEventData.speakers,
        visibleTo: viewEventData.visibleTo,
      });
    }
  }, [viewEventData, isLoading]);

  const handleEditEvent = () => {
    const ruleset = viewEventData.event?.ruleset.value;

    if (ruleset === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${eventId}`);
    } else if (ruleset === "Local v1.0") {
      history.push(`/events/edit/event/localv1/${eventId}`);
    } else if (ruleset === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${eventId}`);
    } else if (ruleset === "Major v2.0") {
      history.push(`/events/edit/event/majorv2/${eventId}`);
    } else if (ruleset === "Local v2.0") {
      history.push(`/events/edit/event/localv2/${eventId}`);
    } else if (ruleset === "Family Reunion v2.0") {
      history.push(`/events/edit/event/losv2/${eventId}`);
    } else {
      history.push("/");
    }
  };

  if (isLoading && eventInfo) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading View Local V2.0</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.pageHeader}>
            <Header
              title={eventInfo?.marketing?.title}
              onClick={() => history.goBack()}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.editBtn}
              children={"EDIT"}
              onClick={handleEditEvent}
            />
          </div>
        </div>

        <div className={styles.pageContent}>
          {eventInfo && (
            <div className={styles.cardContainer}>
              <EventTypeV2Card eventInfo={eventInfo.event} />
            </div>
          )}

          <div className={styles.cardContainer}>
            {eventInfo && (
              <EventV2InfoForm marketingInfo={eventInfo} pageMode="view" />
            )}
          </div>
          <div className={styles.cardContainer}>
            {eventInfo && (
              <PublishingV2InfoForm
                publishingInfo={eventInfo}
                pageMode="view"
              />
            )}
          </div>

          {eventInfo?.event?.location?.value === "onsite" ? (
            <div className={styles.cardContainer}>
              <VenueV2Form venueInfo={eventInfo} pageMode="view" />
            </div>
          ) : (
            <div className={styles.cardContainer}>
              <VirtualCard virtualInfo={eventInfo.virtual} />
            </div>
          )}

          {eventInfo?.event?.location?.value === "onsite" &&
            eventInfo?.sessions?.length > 0 && (
              <div className={styles.cardContainer}>
                <SessionsV2Form sessions={eventInfo.sessions} pageMode="view" />
              </div>
            )}

          <div className={styles.cardContainer}>
            {eventInfo && (
              <TicketingV2Form
                ticketSets={eventInfo.tickets?.value || []}
                setTicketSets={() => {}}
                eventName={eventInfo?.event?.name?.value}
                pageMode="view"
              />
            )}
          </div>

          <div className={styles.cardContainer}>
            <EventHostsCard
              hosts={eventInfo.hosts}
              onClick={() => {
                alert("Add Event Host");
              }}
            />
          </div>

          <div className={styles.cardContainer}>
            {eventInfo && <NotesV2Form notesData={eventInfo} pageMode="view" />}
          </div>
        </div>
      </div>
    </>
  );
}
