import styles from "./CreateNewHomesiteModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { EditSelect } from "../EditSelect/EditSelect";
import Select from "../Select";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "../RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import moment from "moment-timezone";

export function CreateNewHomesiteModal({
  isOpen,
  onCollapse,
  onClick,
  initialData,
  errorsList,
}) {
  useEffect(() => {
    if (isOpen && errorsList.length > 0) {
      const modalContainer = document.querySelector(`.${styles.modal}`);
      if (modalContainer) {
        modalContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [isOpen, errorsList]);

  const { data: statesData, isLoading: isLoadingStates } = useCurrentRoute();

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const getCountryNameFromState = (state) => {
    let foundState = statesData.find((item) => item.state == state);
    if (!foundState) {
      foundState = statesData.find((item) => item.stateId == state);
    }

    const id = foundState ? foundState.countryId : 220;

    return countryIdName[id];
  };

  const [newName, setNewName] = useState("");
  const [newCode, setNewCode] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newStreet1, setNewStreet1] = useState("");
  const [newStreet2, setNewStreet2] = useState("");
  const [newZip, setNewZip] = useState("");
  const [newState, setNewState] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [states, setStates] = useState([]);
  const [newStartsOn, setNewStartsOn] = useState("");
  const [newValidUntil, setNewValidUntil] = useState("");

  const handleFormSubmittal = () => {
    const formData = {
      regionId: initialData.regionId || "",
      name: newName,
      code: newCode,
      location: newLocation,
      city: newCity,
      street1: newStreet1,
      street2: newStreet2,
      zip: newZip,
      state: newState,
      country: newCountry,
      startsOn: newStartsOn,
      validUntil: newValidUntil,
    };
    onClick(formData);
  };

  useEffect(() => {
    if (statesData) {
      const stateNames = statesData.map((state) => state.state);
      setStates(stateNames.filter((state) => state !== "N/A"));
    }
  }, [statesData]);

  useEffect(() => {
    if (initialData) {
      setNewName(initialData.name ?? "");
      setNewCode(initialData.code ?? "");
      setNewLocation(initialData.location ?? "");
      setNewCity(initialData.city ?? "");
      setNewStreet1(initialData.street1 ?? "");
      setNewStreet2(initialData.street2 ?? "");
      setNewZip(initialData.zip ?? "");
      setNewState(initialData.state ?? "");
      setNewCountry(getCountryNameFromState(initialData.country));
      setNewStartsOn(initialData.startsOn || "");
      setNewValidUntil(initialData.validUntil || "");
    }
  }, [initialData]);

  useEffect(() => {
    if (initialData.state && statesData) {
      const selectedState =
        statesData.find(
          (item) => item.abbreviation.trim() === initialData.state.trim()
        ) ||
        statesData.find(
          (item) => item.state.trim() === initialData.state.trim()
        );

      setNewState(selectedState ? selectedState.state : "");
    }
  }, [initialData, statesData]);

  const handleStateChange = (value) => {
    setNewState(value.split(" - ")[1]); //Update to have all cases match when api hookup is done
    const stateWithId = statesData.find(
      (item) => item.state.toString() === value.split(" - ")[1]
    );
    setNewState(stateWithId.state);
  };

  const handleCountrySelect = (country) => {
    setNewCountry(country);
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Postal Code*";
      case "JAMAICA":
        return "Postal Code*";
      default:
        return "ZIP Code*";
    }
  };

  const handleStateSelection = (value) => {
    setNewState(value);
  };

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (statesData) {
      const matchingCountryIds = Object.keys(countryIdName).filter(
        (key) => countryIdName[key] === newCountry
      );

      if (matchingCountryIds.length > 0) {
        const filteredStates = statesData
          .filter((state) =>
            matchingCountryIds.includes(state.countryId.toString())
          )
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        //setStateOptions if they have changed,
        setStateOptions((prevStateOptions) => {
          const newOptions = filteredStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        setStateOptions([]);
      }
    }
  }, [statesData, newCountry]);

  const handleDateTimeChange = (newValue, field) => {
    const [fieldToUpdate, sectionToUpdate] = field.split("-");

    let existingDateTime;
    if (fieldToUpdate === "startsOn") {
      existingDateTime = newStartsOn;
    } else if (fieldToUpdate == "validUntil") {
      existingDateTime = newValidUntil;
    }

    // Initialize updatedMoment based on the existing value or default to today at 12:00 AM
    let updatedMoment = existingDateTime
      ? moment(existingDateTime)
      : moment().startOf("day");

    // Determine if the input is a date or time and update the moment object
    if (sectionToUpdate === "date") {
      const [month, day, year] = newValue.split("/").map(Number);
      updatedMoment = updatedMoment
        .set("month", month - 1)
        .set("date", day)
        .set("year", year);

      // If the existing value doesn't have a time portion, default it to 12:00 AM
      if (
        !existingDateTime ||
        moment(existingDateTime).format("HH:mm") === "00:00"
      ) {
        updatedMoment = updatedMoment.startOf("day");
      }
    } else if (sectionToUpdate === "time") {
      const [hour, minute] = newValue.split(":").map(Number);
      updatedMoment = updatedMoment.set("hour", hour).set("minute", minute);
    }

    // Format the updated value as an ISO string
    const formattedValue = updatedMoment.toISOString();

    if (fieldToUpdate === "startsOn") {
      setNewStartsOn(formattedValue);
    } else if (fieldToUpdate == "validUntil") {
      setNewValidUntil(formattedValue);
    }
  };

  const renderCountryField = () => {
    // Sequence of countries
    if (statesData) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];

      const existingCountry = new Set(
        statesData.map((state) => state.countryId)
      );

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelect}
            options={countryOptions}
            defaultValue={newCountry}
            groupName="homesiteCountry"
          />
        </span>
      );
    }
  };

  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = statesData?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };
    return (
      <div className={styles.stateContainer}>
        <EditSelect
          label={getLabelForCountry(newCountry) || "State*"}
          options={stateOptions}
          placeholder={newState ? newState : "Select"}
          onChange={(value) => handleStateChange(value)}
          className={styles.selectDropdown}
        />
      </div>
    );
  };
  return (
    <Modal
      title="CREATE HOMESITE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          {errorsList.length > 0 && (
            <div className={styles.errorsContainer}>
              {errorsList.map((error) => (
                <div className={styles.error}>{error}</div>
              ))}
            </div>
          )}
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Name*"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Code*"
                  placeholder=""
                  value={newCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 3) {
                      setNewCode(value);
                    }
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Location*"
                  placeholder=""
                  value={newLocation}
                  onChange={(e) => {
                    setNewLocation(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Street 1*"
                  placeholder=""
                  value={newStreet1}
                  onChange={(e) => {
                    setNewStreet1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Street 2"
                  placeholder=""
                  value={newStreet2}
                  onChange={(e) => {
                    setNewStreet2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country* </span>
                {renderCountryField()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City*"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>{renderStateSelection()}</div>
              <div className={styles.inputRow}>
                <InputItem
                  label={getPostalLabelForCountry(newCountry) || "Zip*"}
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>

              <div className={styles.labelAndPickerContainer}>
                <label className={styles.datesLabel}>Starts On*</label>

                <div className={styles.datePickerContainer}>
                  <DatePicker
                    className={styles.date}
                    dateString={
                      newStartsOn
                        ? moment(newStartsOn).format("MM/DD/YYYY")
                        : null
                    }
                    setDate={(newDate) =>
                      handleDateTimeChange(newDate, "startsOn-date")
                    }
                  />
                  <div className={styles.timePickerContainer}>
                    <TimePicker
                      className={styles.date}
                      onChange={(newTime) =>
                        handleDateTimeChange(newTime, "startsOn-time")
                      }
                      timeString={
                        newStartsOn ? moment(newStartsOn).format("HH:mm") : null
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.labelAndPickerContainer}>
                <label className={styles.datesLabel}>Valid Until</label>
                <div className={styles.datePickerContainer}>
                  <DatePicker
                    className={styles.date}
                    dateString={
                      newValidUntil
                        ? moment(newValidUntil).format("MM/DD/YYYY")
                        : null
                    }
                    setDate={(newDate) =>
                      handleDateTimeChange(newDate, "validUntil-date")
                    }
                  />
                  <div className={styles.timePickerContainer}>
                    <TimePicker
                      className={styles.date}
                      onChange={(newTime) =>
                        handleDateTimeChange(newTime, "validUntil-time")
                      }
                      timeString={
                        newValidUntil
                          ? moment(newValidUntil).format("HH:mm")
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <>
                    <Button
                      className={styles.update}
                      children="CREATE"
                      onClick={() => handleFormSubmittal()}
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

CreateNewHomesiteModal.propTypes = {
  isOpen: PropTypes.bool,

  onCollapse: PropTypes.func,

  onClick: PropTypes.func,

  onDelete: PropTypes.func,

  initialData: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    location: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
};
