import React from "react";
import Card from "../../../shared/Card";
import classNames from "classnames";
import InfoItem from "Events/Components/InfoItem";
import Button from "shared/Button";
import PropTypes from "prop-types";

import styles from "./VirtualCard.module.css";
import cardStyles from "../../V1Components/SharedCardStyles.module.css";

/*
 * Interactive component that displays the Virtual Card
 */
export function VirtualCard({ onClick, virtualInfo }) {
  const cardContents = (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.cardHeader}>VIRTUAL</div>
          {/* <Button
            className={classNames(styles.addBtn, cardStyles.cardDarkButton)}
            size="small"
            children="Add"
            onClick={() => onClick(eventType, link)}
          /> */}
        </div>
        <div className={styles.hr} />
        <InfoItem
          label="Virtual Event Type"
          description={
            virtualInfo.eventType?.saved ||
            virtualInfo.eventType?.value ||
            "N/A"
          }
        />
        <InfoItem
          label="Event Link"
          description={
            virtualInfo.eventLink?.saved ||
            virtualInfo.eventLink?.value ||
            "N/A"
          }
        />
      </div>
    </>
  );
  return <Card children={cardContents} />;
}

VirtualCard.propTypes = {
  onClick: PropTypes.func,
  virtualInfo: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
  }),
};
