import React from "react";
import PropTypes from "prop-types";
import styles from "./ErrorModal.module.css";
import { Modal } from "shared/Modal";

export function ErrorModal({ isOpen, onClose, errorMessage }) {
  return (
    <Modal
      title="PUBLISH ERROR"
      isOpen={isOpen}
      onClose={onClose}
      closeBtn="none"
      className={styles.modal}
    >
      <div className={styles.modalContent}>
        <p className={styles.errorMessage}>{errorMessage}</p>
        <button className={styles.closeButton} onClick={onClose}>
          CLOSE
        </button>
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorModal;
