import React from "react";
import styles from "./ADACard.module.css";
import InfoItem from "Events/Components/InfoItem";
import Card from "shared/Card";
import PropTypes from "prop-types";

/**
 * Interactive component that displays the ADA Card
 */
export function ADACard({ entries }) {
  const cardContents = (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.cardHeader}>ADA</div>
      </div>
      {entries.map((entry, index) => {
        // Determine label, param1, and param2 based on component value
        let label, param1, param2;
        switch (entry.component?.value?.toLowerCase()) {
          case "description":
            label = "Description";
            param1 = "";
            param2 = "";
            break;
          case "mobility":
            label = "Mobility";
            param1 = "Option 1";
            param2 = "Option 2";
            break;
          default:
            label = "Checkbox";
            param1 = "Text";
            param2 = "Sub-Text";
        }

        return (
          <div key={index} className={styles.section}>
            <div className={styles.hr} />
            <div className={styles.container}>
              <div className={styles.row}>
                <span className={styles.label}>{label}</span>
                <span className={styles.params}>
                  <span className={styles.param1}>
                    <b>{param1}</b>
                  </span>
                  <span className={styles.param2}>
                    <b>{param2}</b>
                  </span>
                </span>
                <span className={styles.icon}>
                  {entry.icon?.saved || entry.icon?.value ? (
                    <img
                      className={styles.icon}
                      src={entry.icon.saved || entry.icon.value}
                    />
                  ) : (
                    ""
                  )}
                </span>
                <span className={styles.params}>
                  <span className={styles.param1}>
                    {entry.param1.saved || entry.param1.value || "N/A"}
                  </span>
                  <span className={styles.param2}>
                    {entry.component?.value === "description"
                      ? ""
                      : entry.param2.saved || entry.param2.value || "N/A"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return <Card>{cardContents}</Card>;
}

const adaEntryShape = PropTypes.shape({
  component: PropTypes.shape({
    value: PropTypes.string,
    permission: PropTypes.string,
    required: PropTypes.bool,
    field: PropTypes.string,
    saved: PropTypes.string,
  }),
  eventAdaId: PropTypes.string,
  icon: PropTypes.shape({
    value: PropTypes.string,
    permission: PropTypes.string,
    required: PropTypes.bool,
    field: PropTypes.string,
    saved: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  order: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    permission: PropTypes.string,
    required: PropTypes.bool,
    field: PropTypes.string,
    saved: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }),
  param1: PropTypes.shape({
    value: PropTypes.string,
    permission: PropTypes.string,
    required: PropTypes.bool,
    field: PropTypes.string,
    saved: PropTypes.string,
  }),
  param2: PropTypes.shape({
    value: PropTypes.string,
    permission: PropTypes.string,
    required: PropTypes.bool,
    field: PropTypes.string,
    saved: PropTypes.string,
  }),
});

ADACard.propTypes = {
  entries: PropTypes.arrayOf(adaEntryShape).isRequired,
};
