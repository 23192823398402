import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import classNames from "classnames";

import Card from "shared/Card";
//import Button from "shared/Button";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";
import { Select } from "shared/Select/Select";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import TextArea from "shared/TextArea";
import Button from "shared/Button";
import styles from "./SessionsForm.module.css";
import DeleteModal from "Events/Components/DeleteModal";

/**Area to fill out session information */
export function SessionsForm({ sessions, setSessions, onAddSpeaker }) {
  const history = useHistory();

  const [splitRooms, setSplitRooms] = useState({});

  const [btnOpened, setBtnOpened] = useState(false);

  // console.log(hostsAdded);

  const addBtnOnClick = () => {
    setBtnOpened(true);
  };

  const initialSession = {
    type: "",
    ticketGroups: ["Spring Leadership", "Guest"],
    sessionName: "",
    description: "",
    speakers: [],
    rooms: [],
    room: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  };

  const [newAddSessions, setNewAddSessions] = useState(
    Array.isArray(sessions) && sessions.length ? sessions : [initialSession]
  );

  const [showRemoveModal, changeShowRemoveModal] = useState(false);

  // const [localStartTimeZone, setLocalStartTimeZone] = useState("CST");
  // const [localEndTimeZone, setLocalEndTimeZone] = useState("CST");

  const timeZones = ["PST", "MST", "MDT", "CST", "CDT"];

  useEffect(() => {
    if (setSessions) {
      setSessions(newAddSessions);
    }
  }, [newAddSessions]);

  useEffect(() => {
    const roomNames = newAddSessions.flatMap((session) =>
      (session.rooms || []).map((room) =>
        room.split("-").map((val) => val.trim())
      )
    );
    setSplitRooms(Object.fromEntries(roomNames));
  }, [newAddSessions]);

  const handleSessionAdd = () => {
    const newSessions = [
      ...newAddSessions,
      {
        type: "",
        ticketGroups: ["Spring Leadership", "Guest"],
        sessionName: "",
        description: "",
        speakers: [],
        rooms: [],
        room: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
    ];
    setNewAddSessions(newSessions);

    setSessions(newSessions);
  };

  // Handles updates
  const updateSession = (index, key, value) => {
    const updatedSessions = [...newAddSessions];
    updatedSessions[index][key] = value;
    setNewAddSessions(updatedSessions);

    setSessions(updatedSessions);
  };

  const onRemove = (index) => {
    const newArray = [...newAddSessions];

    newArray.splice(index, 1);

    if (newArray.length >= 1) {
      setNewAddSessions(newArray);
      changeShowRemoveModal(false);
    }
  };

  const redirectInvitees = () => {
    history.push("/events/manage-invitees");
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.header}>
            <div className={styles.title}>SESSIONS</div>
            <div className={styles.button}>
              <Button children="Add" size="small" onClick={handleSessionAdd} />
            </div>
          </div>

          <div className={styles.container}>
            {newAddSessions.map((session, index) => (
              <div key={index} className={styles.section}>
                <hr className={styles.hr} />
                <div className={styles.row}>
                  <span className={styles.label}>Visibility</span>

                  <span className={styles.visibleTo}>
                    <Select
                      placeholder={session.type || "Select"}
                      value={session.type}
                      options={["Public", "Invite Only"]}
                      onChange={(type) => updateSession(index, "type", type)}
                    />
                  </span>

                  <div className={styles.selectRemove}>
                    {session.type === "Invite Only" && (
                      <div className={styles.button}>
                        <Button
                          children="Manage Invitees"
                          size="small"
                          onClick={redirectInvitees}
                        />
                      </div>
                    )}

                    <div className={styles.remove}>
                      <Button
                        className={styles.removeButton}
                        children="Remove"
                        size="small"
                        onClick={
                          newAddSessions.length >= 2
                            ? () => changeShowRemoveModal(true)
                            : ""
                        }
                      />
                    </div>

                    <DeleteModal
                      header={"REMOVE SESSION"}
                      description={
                        "Confirm that you would like to remove session?"
                      }
                      isOpen={showRemoveModal}
                      onCollapse={() => changeShowRemoveModal(false)}
                      onClick={() => onRemove(index)}
                      btnName={"REMOVE"}
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.leftCol}>
                    <span className={styles.label}>Ticket Group</span>
                    <div className={styles.ticketGroupContainer}>
                      {session.ticketGroups.map((group, index) => (
                        <span
                          key={index}
                          className={classNames(
                            styles.ticketGroup,
                            styles.text
                          )}
                        >
                          {group}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.session}>
                  <InputItem
                    label="Session Name"
                    placeholder="Click to type"
                    value={session.sessionName || ""}
                    onChange={(e) =>
                      updateSession(index, "sessionName", e.target.value)
                    }
                  />
                </div>
                <div className={classNames(styles.description)}>
                  <TextArea
                    label={"Description"}
                    placeholder="Click to type"
                    value={session.description || ""}
                    onChange={(description) =>
                      updateSession(index, "description", description)
                    }
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.leftCol}>
                    <span className={styles.label}>Speaker Name</span>
                    <div
                      className={styles.addSpeakerButton}
                      onClick={onAddSpeaker}
                    >
                      <AddButton color="#2A3847" description="Add Speaker" />
                    </div>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.finalCol}>
                    <span className={styles.label}>Room</span>
                    <span className={styles.rooms}>
                      <Select
                        options={Object.keys(splitRooms)}
                        placeholder={session.room || "Select"}
                        // onChange={setRoom}
                        onChange={(room) => updateSession(index, "room", room)}
                      />
                    </span>
                  </div>

                  <div className={styles.finalCol}>
                    <span className={styles.capacity}>
                      {splitRooms[session.room] || "Capacity"}
                    </span>
                  </div>
                </div>

                <div className={styles.dateRow}>
                  <div className={styles.dateCol}>
                    <span className={styles.label}>Start Date</span>
                    <span className={styles.datePicker}>
                      <DatePicker
                        dateString={session.startDate || ""}
                        setDate={(sDate) =>
                          updateSession(index, "startDate", sDate)
                        }
                      />
                    </span>
                    <span className={styles.timePicker}>
                      <TimePicker
                        timeString={session.startTime || ""}
                        onChange={(sTime) =>
                          updateSession(index, "startTime", sTime)
                        }
                      />
                    </span>
                    <span className={styles.timezone}>
                      <Select
                        placeholder={session.localStartTimeZone || "CST"}
                        options={timeZones}
                        // onChange={setLocalStartTimeZone}
                        onChange={(zone) =>
                          updateSession(index, "localStartTimeZone", zone)
                        }
                      />
                    </span>
                  </div>

                  <div className={classNames(styles.dateCol)}>
                    <span className={styles.label}>End Date</span>
                    <span className={styles.datePicker}>
                      <DatePicker
                        dateString={session.endDate || ""}
                        setDate={(eDate) =>
                          updateSession(index, "endDate", eDate)
                        }
                      />
                    </span>
                    <span className={styles.timePicker}>
                      <TimePicker
                        timeString={session.endTime || ""}
                        // onChange={setEndTime}
                        onChange={(eTime) =>
                          updateSession(index, "endTime", eTime)
                        }
                      />
                    </span>
                    {/* <span className={styles.timezone}>
                      <Select
                        placeholder={session.localEndTimeZone || "CST"}
                        options={timeZones}
                        // onChange={setLocalEndTimeZone}
                        onChange={(zone) =>
                          updateSession(index, "localEndTimeZone", zone)
                        }
                      />
                    </span> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      }
    />
  );
}

SessionsForm.propTypes = {
  /**Session type displays the name of the session that is assigned by IT during the population of the template. This field is not editable.  */
  type: PropTypes.string,
  /**Ticket group displays the ticket groups that are allowed to attend the session and are assign by IT during the population of the template. This field is not editable.  */
  ticketGroups: PropTypes.arrayOf(PropTypes.string),
  /**Value of session name  */
  sessionName: PropTypes.string,
  /**Sets the value of the session name when changed  */
  setSessionName: PropTypes.func,
  /**Description of the session  */
  description: PropTypes.string,
  /**Sets the value of the description when changed  */
  setDescription: PropTypes.func,
  /**Array of speaker objects  */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function used when adding more speakers  */
  setSpeakers: PropTypes.func,
  /**Array containing the rooms associated with the venue selected in the venue form earlier in the page */
  rooms: PropTypes.arrayOf(PropTypes.string),
  /**Value of the room selected  */
  room: PropTypes.string,
  /**Sets the value of the room when changed  */
  setRoom: PropTypes.func,
  /**Value of the start date  */
  startDate: PropTypes.string,
  /**Sets the value of the start date when changed  */
  setStartDate: PropTypes.func,
  /**Value of the end date  */
  endDate: PropTypes.string,
  /**Sets the value of the end date when changed  */
  setEndDate: PropTypes.func,
  /**Value of the start time  */
  startTime: PropTypes.string,
  /**Sets the value of the start time when changed  */
  setStartTime: PropTypes.func,
  /**Value of the end time  */
  endTime: PropTypes.string,
  /**Sets the value of the end time when changed  */
  setEndTime: PropTypes.func,
};
